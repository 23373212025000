
import {
  deleteCustomProperty,
  updateCustomProperty
} from "@/api/customProperty";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MSelectBox from "@/components/form/MSelectBox.vue";
import MTextField from "@/components/form/MTextField.vue";
import { Options, Vue } from "vue-class-component";
import { CustomProperty, CustomPropertyType } from "@/entities/custom_property";
import { School } from "@/entities/school";
import store from "@/store";
import { saveErrorLog } from "@/api/error";

@Options({
  components: {
    MBaseModal,
    MButton,
    MIcon,
    MSelectBox,
    MTextField
  },
  emits: ["close"],
  props: {
    school: Object,
    customProperty: Object
  }
})
export default class MsEditPropertyModal extends Vue {
  school!: School;
  customProperty!: CustomProperty;
  title = "";
  type: CustomPropertyType = "string";
  types: { type: CustomPropertyType; text: string }[] = [
    {
      type: "string",
      text: "テキスト"
    },
    {
      type: "number",
      text: "数字"
    },
    {
      type: "select",
      text: "選択式"
    }
  ];
  choices: string[] = ["", ""];
  shareToAdmin = false;
  showToStudent = false;
  editableByStudent = false;

  get validTitle() {
    return this.title.length > 0;
  }

  get validChoices() {
    return this.type !== "select" || this.choices.length >= 2;
  }

  get validData() {
    return this.validTitle && this.validChoices;
  }

  addChoice() {
    this.choices.push("");
  }

  deleteChoice(index: number) {
    this.choices.splice(index, 1);
  }

  async update() {
    if (!this.validData) {
      return;
    }

    const choices = this.type === "select" ? this.choices : [];
    try {
      store.commit("START_LOADING", "更新中...");
      await updateCustomProperty(this.customProperty.ref, {
        title: this.title,
        type: this.type,
        shareToAdmin: this.shareToAdmin,
        choices,
        showToStudent: this.showToStudent,
        editableByStudent: this.showToStudent ? this.editableByStudent : false
      });
      store.commit("END_LOADING");
    } catch (e) {
      store.commit("END_LOADING");
      alert(`オリジナル情報の更新に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to update custom property"
      );
      return;
    }
    this.$router.go(0);
  }

  async deleteCustomProperty() {
    const res = confirm(
      `このオリジナル情報を削除すると、全ての生徒から「${this.customProperty.data.title}」の情報が削除されますが、本当に削除しても良いですか？`
    );
    if (!res) {
      return;
    }

    try {
      store.commit("START_LOADING", "削除中...");
      await deleteCustomProperty(this.customProperty.ref);
      store.commit("END_LOADING");
    } catch (e) {
      store.commit("END_LOADING");
      alert(`削除に失敗しました\n\n${e}`);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to delete custom property"
      );
      return;
    }
    this.$router.go(0);
  }

  close() {
    this.$emit("close");
  }

  created() {
    this.title = this.customProperty.data.title;
    this.type = this.customProperty.data.type;
    this.choices = this.customProperty.data.choices;
    this.shareToAdmin = this.customProperty.data.shareToAdmin;
    this.showToStudent = this.customProperty.data.showToStudent ?? false;
    this.editableByStudent =
      this.customProperty.data.editableByStudent ?? false;
  }
}
